// files

<template>
  	<v-container fluid pa-0 ma-0>
	    <v-card max-width="500">
	      	<v-toolbar color="indigo lighten-2" dense>
	        	<v-toolbar-title>Uploads</v-toolbar-title>
	      	</v-toolbar>
	      	<v-list dark dense ref="list">
				<template v-for="(item, index) in uploads">
		            <v-list-item :key="index" dense>
		              	<template>
					        <v-list-item-avatar size="40">
					          	<v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
					        </v-list-item-avatar>
			                <v-list-item-content>
								<v-list-item-title v-text="item.name"></v-list-item-title>
								<v-list-item-title v-text="item.type"></v-list-item-title>
								<v-list-item-subtitle>{{ item.lastModified | toJSDT }}</v-list-item-subtitle>
			                </v-list-item-content>
			                <v-list-item-action>
			                	<v-list-item-action-text class="ml-3">{{ item.size | toMB }}</v-list-item-action-text>
			                </v-list-item-action>
					        <v-list-item-avatar size="40" right>
								<v-progress-circular v-if="cannotRemove(item)" :rotate="360" :size="60" :width="5"
									:value="item.progress" class="no-transition"
									:color="isActiveUpload(index) ? 'warning' : 'teal'">
									{{ uploadProgress(index) }}
								</v-progress-circular>
									<!--:indeterminate="isActiveUpload(index)"-->
					          	<v-btn v-else icon @click="onRemove(index)"><v-icon color="red">cancel</v-icon></v-btn>
					        </v-list-item-avatar>
		              	</template>
	            	</v-list-item>
	            </template>
	      	</v-list>
			<v-card-actions>
      			<v-btn color="indigo lighten-2" @click="onPickFiles()" :disabled="cannotPick()">Pick Files</v-btn>
				<v-spacer />
            	<input type="file" multiple="multiple" id="uploads" style="display: none"
            		ref="files" accept=".jpg,.png,.xls,.xlsx,.csv,.pdf" @change="onFilesPicked">
      			<v-btn color="indigo lighten-2" @click="onUpload()" :disabled="cannotUpload()">Start Upload</v-btn>
    		</v-card-actions>
    	</v-card>
	</v-container>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		name: 'files',
		components: {
		},
		data () {
			return {
				icons: {
					'type:': { icon: 'not_listed_location', iconClass: 'grey red--text' },
					'type:application/pdf': { icon: 'picture_as_pdf', iconClass: 'pink accent-2 white--text' },
					'type:image/png': { icon: 'image', iconClass: 'teal white--text' },
					'type:image/jpeg': { icon: 'image', iconClass: 'teal white--text' },
					'type:text/csv': { icon: 'description', iconClass: 'red white--text' },
					'type:application/vnd.ms-excel': { icon: 'description', iconClass: 'blue white--text' },
					'type:application/msword': { icon: 'description', iconClass: 'blue white--text' },
					'type:image/jpg': { icon: 'image', iconClass: 'teal white--text' },
					'type:text/plain': { icon: 'description', iconClass: 'blue white--text' },
				},
				uploads: [],
				uploading: false,
				uploadActive: false,
				hInterval: null,
				firstUndone: null,
				activeUpload: -1,
			}
		},
		computed: {
			...mapGetters({
				currentProgress: 'files/getProgress',
			}),
		},
		methods: {
			onPickFiles() {
				this.$refs.files.click()
			},
			onFilesPicked(e) {
				const files = e.target.files
				if(!files.length) return;
				this.uploads = this.uploads.filter(x => x.progress !== 'ok')
				for(var file of files) {
					if(this.uploads.find(x => x.name == file.name) === undefined) {
						this.uploads.push(file)
					}
				}
				for(var upload of this.uploads) {
					upload.icon = this.icons['type:' + upload.type].icon
					upload.iconClass = this.icons['type:' + upload.type].iconClass
					upload.progress = 0//Math.floor(Math.random() * 100);
				}
				this.$refs.files.value = []
			},
			uploadCompleted() {

			},
			uploadFile() {
				this.activeUpload = this.uploads.findIndex(item => item.progress !== 'ok')
				if(this.activeUpload >= 0) {
					let formData = new FormData()
					formData.append('file', this.uploads[this.activeUpload])
					formData.append('time', this.uploads[this.activeUpload].lastModified)
					formData.append('mime', this.uploads[this.activeUpload].type)
	            	this.uploadActive = true
					//var self = this
					console.log(formData)
					this.$store.dispatch('files/uploadFile', formData)
					.then((response) => {
	            		let upload = this.uploads[this.activeUpload]
	            		upload.progress = 'ok'
	            		this.uploads.splice(this.activeUpload, 1, upload)					
	            		this.activeUpload = -1
	            		this.$store.commit('files/setProgress', 0)
	            		this.uploadActive = false
					})
					.catch((error) => {
						console.log(error)
						this.uploads[this.activeUpload].progress = '--'
	            		this.activeUpload = -1
	            		this.uploadActive = false
						this.uploading = false
		        		clearInterval(this.hInterval)
		        		this.hInterval = null
		            	console.log('upload stopped')
					})
				} else {
					this.uploading = false
	        		clearInterval(this.hInterval)
	        		this.hInterval = null
	            	console.log('upload stopped')
				}
			},
			onUpload() {
			    if (!this.hInterval) {
		            console.log('upload started')
		            this.uploading = true
		        	this.hInterval = setInterval(() => {
		            	if(!this.uploadActive && this.uploading) this.uploadFile()
		        	}, 200)
		    	}
			},
			onRemove(index) {
				this.uploads.splice(index, 1); 
			},
			getIcon(item) {
				return this.icons[item.type]
			},
			cannotUpload() {
				let undone = this.uploads.reduce((total, x) => x.progress !== 'ok', 0)
				return (this.uploading || (!undone))
			},
			cannotPick() {
				return this.uploading
			},
			cannotRemove(item) {
				return this.uploading || (!!(item.progress === 'ok'))
			},
			isActiveUpload(index) {
				return index == this.activeUpload
			},
			uploadProgress(index) {
				return (index == this.activeUpload) ? this.currentProgress : this.uploads[index].progress
			},
		},
		mounted() {
			//console.log('this.$refs')
			//console.log(this.$refs)
		},
		watch: {
			currentProgress() {
				if((this.activeUpload >= 0) && this.currentProgress > (this.uploads[this.activeUpload].progress + 10)) {
	        		let upload = this.uploads[this.activeUpload]
	        		upload.progress = this.currentProgress
	        		//this.uploads.splice(this.activeUpload, 1, upload)
	        		this.$set(this.uploads, this.activeUpload, upload)
	        		//Vue.set(this.myArray, index, newValue)
	        		//Vue.set(this.myObject, key, value)
	        		//Vue.delete(this.myObject, key)
        		}
			}
		},
	}
</script>

<style>
.no-transition .progress-circular__overlay {
  transition: all 0.01s ease-in-out;
}
</style>